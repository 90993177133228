import React from 'react';
import { useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';

import ScoreChart from '../../components/ScoreChart';
import selectUser from '../../store/selectors/appSelector';
import { PRIMARY_FONT } from '../../theme/fontConstants';

import OpeningsAndPlacementsWidget from './OpeningsAndPlacementsWidget';
import PotentialRevenueWidget from './PotentialRevenueWidget';
import WorkerStatusWidget from './WorkerStatusWidget';

const Dashboard = () => {
  const user = useSelector(selectUser);
  const adminRevenueLabels = {
    title: LanguageConverter('dashboard.widget.admin.revenue.title'),
    totalRevenue: LanguageConverter('dashboard.widget.admin.revenue.total'),
    scheduled: LanguageConverter('dashboard.widget.admin.revenue.booked'),
  };

  const agencyRevenueLabels = {
    title: LanguageConverter('dashboard.widget.agency.revenue.title'),
    availableRevenue: LanguageConverter('dashboard.widget.agency.revenue.available'),
    totalRevenue: LanguageConverter('dashboard.widget.agency.revenue.total'),
    totalRevenueDescription: LanguageConverter('dashboard.widget.agency.revenue.total.description'),
    scheduled: LanguageConverter('dashboard.widget.agency.revenue.scheduled'),
  };

  const revenueLabels = user?.role === UserRole.ADMIN ? adminRevenueLabels : agencyRevenueLabels;

  return (
    <Box padding="32px" display="flex" flexDirection="column" gap="32px">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Typography sx={{ fontFamily: PRIMARY_FONT[700], fontSize: 36 }}>
          {user?.role === UserRole.EMPLOYER
            ? LanguageConverter('dashboard.agency.title')
            : LanguageConverter('dashboard.admin.title')}
        </Typography>
        {user?.role === UserRole.EMPLOYER && (
          <ScoreChart
            label={LanguageConverter('dashboard.widget.reliability.title')}
            score={0.85}
            description={LanguageConverter('dashboard.widget.reliability.description')}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        flexWrap="wrap"
        gap="12px"
      >
        <WorkerStatusWidget
          // TODO: use real data
          isLoading={false}
          activeWorkers={233}
          pendingOnboarding={733}
          waitingVerification={133}
          atRisk={2}
          total={27}
        />

        <Box>
          <PotentialRevenueWidget
            labels={revenueLabels}
            availableAmount={389433}
            totalEarnedAmount={734231}
            scheduledAmount={216267}
          />
        </Box>
        <OpeningsAndPlacementsWidget
          isLoading={false}
          totalOpenings={1534}
          totalActivePlacements={1014}
          percentStaffed={30}
          totalWorkerCancellations={80}
          cancellationToPlacementRatio={0.07}
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
