import { get, map } from 'lodash/fp';
import sortBy from 'lodash/fp/sortBy';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  skillsList: [],
  certsList: [],
  isSkillsLoading: false,
  isCertsLoading: false,
};
export const skillsAndCertsSliceSlice = createSlice({
  name: 'skillsAndCertsSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    getSkills: (state) => {
      state.isSkillsLoading = true;
    },
    getSkillsProcessed: (state, action) => {
      state.isSkillsLoading = false;
      const documents = get(['payload', 'data', 'documents'], action);
      const skillsList = map((skill) => {
        const { _id } = skill;
        return {
          id: _id,
          ...skill,
        };
      }, documents);

      state.skillsList = sortBy((n) => n.metadata.created, skillsList).reverse() || [];
      state.totalSkillRowCount = get(['payload', 'data', 'count'], action);
    },
    getSkillsError: (state) => {
      state.isSkillsLoading = false;
    },
    getCerts: (state) => {
      state.isCertsLoading = true;
    },
    getCertsProcessed: (state, action) => {
      state.isCertsLoading = false;
      const documents = get(['payload', 'data', 'documents'], action);
      const certsList = map((cert) => {
        const { _id } = cert;
        return {
          id: _id,
          ...cert,
        };
      }, documents);

      state.certsList = sortBy((n) => n.metadata.created, certsList).reverse() || [];
      state.totalCertsRowCount = get(['payload', 'data', 'count'], action);
    },
    getCertsError: (state) => {
      state.isCertsLoading = false;
    },

    sendNewSkillToBackend: (state) => {
      state.isSendingNewSkillToBackend = true;
    },

    sendNewSkillToBackendProcessed: (state) => {
      state.isSendingNewSkillToBackend = false;
    },
    sendNewSkillToBackendError: (state) => {
      state.isSendingNewSkillToBackend = false;
    },

    sendNewCertToBackend: (state) => {
      state.isSendingNewCertToBackend = true;
    },

    sendNewCertToBackendProcessed: (state) => {
      state.isSendingNewCertToBackend = false;
    },
    sendNewCertToBackendError: (state) => {
      state.isSendingNewCertToBackend = false;
    },

    deleteSkill: (state) => {
      state.isDeletingSkill = true;
    },
    deleteSkillProcessed: (state) => {
      state.isDeletingSkill = false;
    },
    deleteSkillError: (state) => {
      state.isDeletingSkill = false;
    },

    deleteCert: (state) => {
      state.isDeletingCert = true;
    },
    deleteCertProcessed: (state) => {
      state.isDeletingCert = false;
    },
    deleteCertError: (state) => {
      state.isDeletingCert = false;
    },
  },
});

export const skillsAndCertsReducer = skillsAndCertsSliceSlice.reducer;

export const {
  sendNewSkillToBackendProcessed,
  sendNewSkillToBackendError,
  sendNewSkillToBackend,
  sendNewCertToBackendProcessed,
  sendNewCertToBackendError,
  sendNewCertToBackend,
  deleteSkill,
  deleteSkillProcessed,
  deleteSkillError,
  deleteCert,
  deleteCertProcessed,
  deleteCertError,
  getSkills,
  getSkillsProcessed,
  getSkillsError,
  getCerts,
  getCertsProcessed,
  getCertsError,
} = skillsAndCertsSliceSlice.actions;
