import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import { Circle, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import pin from '../../assets/icons/MapPin.svg';

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '20px',
};

const WaeMap = ({ sx, center, markers, zoomLevel, onClick }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(
    (currentMap) => {
      const bounds = new window.google.maps.LatLngBounds(center);

      if (currentMap.current) {
        currentMap.current.fitBounds(bounds);
        setMap(currentMap);
      }
    },
    [center]
  );

  // eslint-disable-next-line no-unused-vars
  const onUnmount = React.useCallback((thisMap) => {
    setMap(null);
  }, []);

  // need a box around map with a min height to ensure it always tries to appear
  return isLoaded ? (
    <Box sx={{ width: '100%', minHeight: '10px', ...sx }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoomLevel}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={onClick}
      >
        {(markers || []).map((marker) => (
          <>
            <Marker icon={marker.icon || pin} onLoad={onLoad} position={marker.position} />
            {marker?.circle && (
              <Circle
                onLoad={onLoad}
                onUnmount={onUnmount}
                center={marker?.position}
                radius={marker?.circle?.radius} // Meters
                options={{
                  strokeColor: marker?.circle?.strokeColor || 'transparent',
                  fillColor: marker?.circle?.fillColor || 'blue',
                  fillOpacity: marker?.circle?.fillOpacity || 0.35,
                }}
              />
            )}
          </>
        ))}
      </GoogleMap>
    </Box>
  ) : (
    <>CONTENT</>
  );
};

export default WaeMap;
WaeMap.propTypes = {
  center: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  markers: PropTypes.arrayOf(PropTypes.shape({})),
  zoomLevel: PropTypes.number,
  onClick: PropTypes.func,
  sx: PropTypes.shape({}),
};
