import * as React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import theme from '../../../../../theme';

const LocationCard = ({ description, state, city, address, address2, zipcode, id }) => {
  const cardTheme = get(['candidate', 'components', 'groupDetails', 'availableShiftCard'], theme);
  const sxForCard = {
    backgroundColor: get('backgroundColor', cardTheme),
    display: 'flex',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(3),
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  };
  const sxForPrimaryText = {
    color: get(['primaryText', 'color'], cardTheme),
    fontFamily: get(['primaryText', 'font'], cardTheme),
    fontSize: get(['primaryText', 'fontSize'], cardTheme),
  };
  const sxForSecondaryText = {
    color: get(['secondaryText', 'color'], cardTheme),
    fontFamily: get(['secondaryText', 'font'], cardTheme),
    fontSize: get(['secondaryText', 'fontSize'], cardTheme),
  };
  const sxForRow = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.25),
  };

  const addressString = `${address} ${address2 || ''} ${city}, ${state} ${zipcode}`;

  return (
    <Box sx={sxForCard} key={id}>
      <Box sx={{ width: '100%' }}>
        <Box sx={sxForRow}>
          <Typography sx={sxForPrimaryText}>{description}</Typography>
        </Box>
        <Box sx={sxForRow}>
          <Typography sx={sxForSecondaryText}>{addressString}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LocationCard;

LocationCard.propTypes = {
  description: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
  address: PropTypes.string,
  address2: PropTypes.string,
  zipcode: PropTypes.string,
  id: PropTypes.string,
};
