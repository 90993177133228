import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectCorporationList = (state) => get('corporationList', state);

export const selectCorporations = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.corporations
);

export const selectIsLoading = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.isLoading
);
export const selectTotalRowCount = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.totalRowCount
);
export const selectDepartments = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.departments
);
export const selectIsLoadingDepartments = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.isLoadingDepartments
);
export const selectLocations = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.locations
);
export const selectLocationsPage = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.locationsPage
);
export const selectIsLoadingLocations = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.isLoadingLocations
);
export const selectLocationsCount = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.locationsCount
);
export const selectClickedLatLng = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.latLng
);
export const selectIsLoadingCreate = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.isLoadingCreate
);
export const selectCreatedLocationId = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.createdLocationId
);
