import React from 'react';
import PropTypes from 'prop-types';

import { Box, CardContent, Typography } from '@mui/material';
import Card from '@mui/material/Card';

import { BLACK, PRIMARY_COLOR } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';

const PotentialRevenueWidget = ({
  labels,
  availableAmount,
  totalEarnedAmount,
  scheduledAmount,
}) => (
  <Card sx={{ width: '427px', height: '233px' }}>
    <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Box>
        <Typography gutterBottom variant="h2" sx={{ fontSize: 20, fontFamily: PRIMARY_FONT[700] }}>
          {labels.title}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Typography sx={{ fontSize: 40, fontFamily: PRIMARY_FONT[400] }}>
            ${availableAmount.toLocaleString('en-US')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Typography sx={{ fontSize: 14, fontFamily: PRIMARY_FONT[300] }}>
            {labels?.availableRevenue || ''}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              borderRight: `1px solid ${BLACK[70]}`,
            }}
          >
            <Typography
              color={PRIMARY_COLOR[70]}
              sx={{ fontSize: 24, fontFamily: PRIMARY_FONT[300] }}
            >
              ${totalEarnedAmount?.toLocaleString('en-US')}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography sx={{ fontSize: 14, fontFamily: PRIMARY_FONT[300] }}>
              {labels?.totalRevenue || ''}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography sx={{ fontSize: 14, fontFamily: PRIMARY_FONT[300] }}>
              {labels?.totalRevenueDescription || ''}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography sx={{ fontSize: 24, fontFamily: PRIMARY_FONT[300] }}>
              ${scheduledAmount.toLocaleString('en-US')}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography sx={{ fontSize: 14, fontFamily: PRIMARY_FONT[300] }}>
              {labels?.scheduled || ''}
            </Typography>
          </Box>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

PotentialRevenueWidget.propTypes = {
  labels: PropTypes.shape({
    title: PropTypes.string.isRequired,
    availableRevenue: PropTypes.string,
    totalRevenue: PropTypes.string,
    totalRevenueDescription: PropTypes.string,
    scheduled: PropTypes.string,
  }),
  availableAmount: PropTypes.number,
  totalEarnedAmount: PropTypes.number,
  scheduledAmount: PropTypes.number,
};

export default PotentialRevenueWidget;
