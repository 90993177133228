import React, { useCallback } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import corporationCreateRequestSchema from '@careerstart/wae-common/schema/corporation/create/create.post.req.json';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';

import BackdropCircularProgress from '../../../components/BackdropCircularProgress';
import { validateSchema } from '../../../components/Form/validations';
import selectUser from '../../../store/selectors/appSelector';
import launchDarklyToggles from '../../../utils/launchDarklyToggles';

import formFieldDataBHDecoupled from './formData/formFieldDataBHDecoupled';
import formFieldDataBHNotDecoupled from './formData/formFieldDataBHNotDecoupled';
import CreateCorporationForm from './CreateCorporationForm';
import { postCorporation, postCorporationBHDecoupled } from './reducer';

const CreateCorporation = ({ flags }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isBhDecouple = launchDarklyToggles(flags, 'isWaeBhDecoupled');
  const onSubmit = useCallback(
    (values) => {
      const createPayload = {
        fields: {
          name: `${values?.name}`,
        },
      };

      if (
        !(validateSchema(createPayload, corporationCreateRequestSchema)?.FORM_ERROR?.length > 0) &&
        isBhDecouple
      ) {
        dispatch(postCorporationBHDecoupled(createPayload));
      } else if (!isBhDecouple) {
        dispatch(
          postCorporation({
            bullhornID: `${values.BullhornID}`,
          })
        );
      }
    },
    [dispatch, isBhDecouple]
  );

  const formFieldData = isBhDecouple ? formFieldDataBHDecoupled : formFieldDataBHNotDecoupled;

  return (
    UserRole.ADMIN === user.role &&
    (<CreateCorporationForm formData={formFieldData} onSubmit={onSubmit} /> || (
      <BackdropCircularProgress />
    ))
  );
};

CreateCorporation.propTypes = {
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(CreateCorporation);
