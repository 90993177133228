const name = {
  field: 'name',
  headerClassName: 'theme-header',
  headerName: 'Name',
  minWidth: 250,
};

const corporationListColumnData = [name];

export default corporationListColumnData;
