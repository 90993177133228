import FormTextField from '../../../../../main/components/Form/FormTextField/FormTextField';

const formFieldDataBHDecoupled = [
  {
    ComponentType: FormTextField,
    label: `Corporation Name`,
    name: 'name',
    required: true,
    fullWidth: true,
    maxRows: 5,
    sx: {
      borderRadius: '40px',
    },
  },
];

export default formFieldDataBHDecoupled;
