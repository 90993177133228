import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Grid, Typography } from '@mui/material';

import { apiPost } from '../../../../../../datahub/axios';
import { buildFormField } from '../../../../../../main/components/Form/formFieldFactory';
import WaeButton from '../../../../../components/Button';
import LazyList from '../../../../../components/LazyList/LazyList';
import selectUser from '../../../../../store/selectors/appSelector';
import {
  selectCreatedLocationId,
  selectIsLoadingCreate,
  selectIsLoadingLocations,
  selectLocations,
  selectLocationsCount,
  selectLocationsPage,
} from '../../../../../store/selectors/corporationListSelectors';
import theme from '../../../../../theme';
import { getAverageCoordinates } from '../../../../../utils/locationHelpers';
import { showSnackbar } from '../../../../app';
import { clearCreatedLocation, createLocation, getLocations } from '../../reducer';
import FormFieldData from '../FormData/FormFieldDataLocations';

import LocationCard from './LocationCard';
import NoLocationsCard from './NoLocationsCard';

const LocationCardWithData = ({ item }) => (
  <LocationCard
    description={item.description}
    state={item.state}
    city={item.city}
    address={item.address}
    address2={item.address2}
    zipcode={item.zipcode}
    id={item._id}
  />
);

LocationCardWithData.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    address2: PropTypes.string,
    zipcode: PropTypes.string,
    _id: PropTypes.string,
  }),
};

const LocationsFormBody = ({ onSubmit, corporation, reset, form }) => {
  const formState = useFormState();
  const dispatch = useDispatch();

  const currentFormValues = formState?.values;
  const isLoadingLocations = useSelector(selectIsLoadingLocations);
  const locationsPage = useSelector(selectLocationsPage);
  const locations = useSelector(selectLocations);
  const locationsCount = useSelector(selectLocationsCount);
  const isLoadingCreate = useSelector(selectIsLoadingCreate);
  const createdLocationId = useSelector(selectCreatedLocationId);

  const [isAddLocationOpen, setIsAddLocationOpen] = useState(false);

  const [calcdLatLng, setCalcdLatLng] = useState(null);
  const user = useSelector(selectUser);
  const token = user?.token;

  const validateAddress = useCallback(
    async (addressToValidate) => {
      const { street, city, state, zip } = addressToValidate;

      await apiPost(
        'location/validate',
        {
          city,
          address: street,
          state: state?.value,
          zipcode: zip,
        },
        token
      )
        .then((webResponse) => {
          const newLatLng = webResponse?.data;
          if (!newLatLng || !newLatLng.lat || !newLatLng.lng) {
            dispatch(showSnackbar('location.create.validate.fail'));
            setCalcdLatLng({
              lat: 0,
              lng: 0,
            });
            form.change('lat', 0);
            form.change('lng', 0);
            return;
          }
          form.change('lat', newLatLng?.lat);
          form.change('lng', newLatLng?.lng);
          setCalcdLatLng({
            lat: newLatLng.lat,
            lng: newLatLng.lng,
          });
        })
        .catch(() => {
          dispatch(showSnackbar('location.create.validate.fail'));
          setCalcdLatLng({
            lat: 0,
            lng: 0,
          });
          form.change('lat', 0);
          form.change('lng', 0);
        });
    },
    [dispatch, form, token]
  );

  useEffect(() => {
    if (
      !calcdLatLng &&
      currentFormValues?.address1 &&
      currentFormValues?.city &&
      currentFormValues?.state &&
      currentFormValues?.zipcode
    ) {
      validateAddress({
        street: currentFormValues?.address1,
        city: currentFormValues?.city,
        state: currentFormValues?.state,
        zip: currentFormValues?.zipcode,
      });
    }
  }, [calcdLatLng, currentFormValues, validateAddress]);

  const rowSx = {
    width: '100%',
    margin: theme.spacing(3, 0, 2, 0),
  };

  const getNextData = () => {
    if (!isLoadingLocations) {
      const reqParams = {
        page: locationsPage,
        limit: 5,
        filters: [
          {
            field: 'corporation._id',
            value: corporation.id,
            operation: 'equalsID',
          },
        ],
      };
      dispatch(getLocations(reqParams));
    }
  };

  useEffect(() => {
    if (createdLocationId) {
      const reqParams = {
        page: 0,
        limit: 1,
        filters: [
          {
            field: '_id',
            value: createdLocationId,
            operation: 'equalsID',
          },
        ],
      };
      dispatch(getLocations(reqParams));
      dispatch(clearCreatedLocation(reqParams));
      setIsAddLocationOpen(false);
    }
  }, [createdLocationId, dispatch]);

  const onMapClick = (ev) => {
    form.change('lat', ev.latLng.lat());
    form.change('lng', ev.latLng.lng());
  };

  const clearCalcdLatLng = () => {
    setCalcdLatLng(null);
  };

  const handleSubmit = () => {
    if (isLoadingCreate) {
      return;
    }
    dispatch(
      createLocation({
        corporation: corporation.id,
        description: currentFormValues.desc,
        address1: currentFormValues.address1,
        address2: currentFormValues.address2,
        city: currentFormValues.city,
        state: currentFormValues.state.value,
        zip: currentFormValues.zipcode,
        coordinate: {
          lat: currentFormValues.lat,
          long: currentFormValues.lng,
        },
      })
    );
  };

  return (
    <Grid sx={{ height: '100%', padding: theme.spacing(1), width: '100%', overflowY: 'auto' }}>
      <form
        onSubmit={onSubmit}
        style={{
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: theme?.components?.detailView?.typography?.header?.fontSize,
            fontFamily: theme?.components?.detailView?.typography?.header?.fontFamily,
            color: theme?.components?.detailView?.typography?.header?.fontColor,
          }}
        >
          {corporation?.name}
        </Typography>
        {!isAddLocationOpen && (
          <>
            {corporation?.mailingAddress && (
              <Typography
                sx={{
                  fontSize: theme?.detailView?.typography?.semiBoldText.fontSize,
                  color: theme?.detailView?.typography?.semiBoldText.fontColor,
                  fontFamily: theme?.detailView?.typography?.semiBoldText.fontFamily,
                }}
              >
                {LanguageConverter('location.create.headers.billingAddress')}:{' '}
                {corporation?.mailingAddress}
              </Typography>
            )}
            <Box sx={rowSx}>
              {buildFormField(
                FormFieldData.companyMap(
                  getAverageCoordinates({ locations }) || { lat: 0, lng: 0 },
                  locations
                )
              )}
            </Box>
            <LazyList
              backgroundColor={theme?.components?.detailView?.palette?.backgroundColor}
              Card={LocationCardWithData}
              getNextData={getNextData}
              items={locations || []}
              itemCount={locationsCount}
              skeletonVariant="Text"
              skeletonBackgroundColor={theme?.components?.detailView?.palette?.lightLazyLoadColor}
              NoDataCard={NoLocationsCard}
            />
          </>
        )}

        {isAddLocationOpen ? (
          <>
            {/*
                OnChange below is needed to re-calc lat/lng when user changes a relevant address field
              */}
            <OnChange name="address1">{clearCalcdLatLng}</OnChange>
            <OnChange name="city">{clearCalcdLatLng}</OnChange>
            <OnChange name="state">{clearCalcdLatLng}</OnChange>
            <OnChange name="zipcode">{clearCalcdLatLng}</OnChange>

            <Box sx={rowSx}>{buildFormField(FormFieldData.desc)}</Box>
            <Box sx={rowSx}>{buildFormField(FormFieldData.address1)}</Box>
            <Box sx={rowSx}>{buildFormField(FormFieldData.address2)}</Box>
            <Box sx={{ ...rowSx, display: 'flex', alignItems: 'center' }}>
              {buildFormField(FormFieldData.city)}
              {buildFormField(FormFieldData.state)}
            </Box>
            <Box sx={rowSx}>{buildFormField(FormFieldData.zip)}</Box>
            {calcdLatLng && (
              <>
                <Box sx={rowSx}>
                  {buildFormField(
                    FormFieldData.map((ev) => onMapClick(ev), {
                      lat: currentFormValues?.lat || calcdLatLng.lat,
                      lng: currentFormValues?.lng || calcdLatLng.lng,
                    })
                  )}
                </Box>
                <Box sx={rowSx}>{buildFormField(FormFieldData.lat)}</Box>
                <Box sx={rowSx}>{buildFormField(FormFieldData.lng)}</Box>
              </>
            )}

            <Grid container item justifyContent="flex-end" sx={{ width: '100%' }}>
              <WaeButton
                data-testid="modalSubmitButton"
                text={LanguageConverter('buttonText.goBack')}
                onClick={() => {
                  setIsAddLocationOpen(false);
                  reset();
                }}
              />
              <WaeButton
                data-testid="modalSubmitButton"
                text={LanguageConverter('buttonText.save')}
                onClick={handleSubmit}
                disabled={Object.keys(formState.errors).length !== 0}
              />
            </Grid>
          </>
        ) : (
          <WaeButton
            data-testid="modalSubmitButton"
            text={LanguageConverter('location.create.add')}
            onClick={() => setIsAddLocationOpen(true)}
          />
        )}
      </form>
    </Grid>
  );
};

export default LocationsFormBody;

LocationsFormBody.propTypes = {
  onSubmit: PropTypes.func,
  corporation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    mailingAddress: PropTypes.string,
  }),
  reset: PropTypes.func,
  form: PropTypes.shape({
    change: PropTypes.func,
  }),
};
