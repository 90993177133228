import React from 'react';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';

import BackdropCircularProgress from '../../../../../components/BackdropCircularProgress';

import LocationsFormBody from './LocationsFormBody';

const Locations = ({ corporation }) =>
  (
    <RFForm
      onSubmit={() => {}}
      initialValues={{}}
      destroyOnUnregister
      render={({ handleSubmit, form }) => (
        <LocationsFormBody
          onSubmit={handleSubmit}
          corporation={corporation}
          reset={() => form.reset()}
          form={form}
        />
      )}
    />
  ) || <BackdropCircularProgress />;

export default Locations;

Locations.propTypes = {
  corporation: PropTypes.shape({}),
};
