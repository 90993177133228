import * as React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Grid, Skeleton, Typography } from '@mui/material';

import { PRIMARY_FONT } from '../../../theme/fontConstants';

const OpeningsAndPlacementsWidget = ({
  isLoading,
  totalOpenings,
  totalActivePlacements,
  percentStaffed,
  totalWorkerCancellations,
  cancellationToPlacementRatio,
}) => (
  <Box sx={{ width: '500px', height: '233px', borderRadius: '8px', boxShadow: 2 }}>
    {isLoading ? (
      <Skeleton height="inherit" variant="rectangular" sx={{ borderRadius: '8px' }} />
    ) : (
      <Grid
        container
        justifyContent="space-between"
        sx={{ padding: '16px', direction: 'row', height: '100%' }}
      >
        <Typography sx={{ fontSize: '20px', fontFamily: PRIMARY_FONT[700] }}>
          {LanguageConverter('dashboard.widget.openingsVsPlaced.title')}
        </Typography>
        <Grid container justifyContent="space-between">
          <Grid justifyContent="center" sx={{ direction: 'row' }}>
            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[400] }}>
              {LanguageConverter('dashboard.widget.openingsVsPlaced.totalOpenings')}
            </Typography>
            <Typography
              sx={{
                fontSize: '40px',
                fontFamily: PRIMARY_FONT[300],
                color: '#491599',
                justifySelf: 'center',
              }}
            >
              {totalOpenings.toLocaleString('en-US')}
            </Typography>
          </Grid>
          <Grid justifyContent="center" sx={{ direction: 'row' }}>
            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[400] }}>
              {LanguageConverter('dashboard.widget.openingsVsPlaced.myPlacements')}
            </Typography>
            <Typography
              sx={{
                fontSize: '40px',
                fontFamily: PRIMARY_FONT[300],
                color: '#491599',
                justifySelf: 'center',
              }}
            >
              {totalActivePlacements.toLocaleString('en-US')}
            </Typography>
          </Grid>
          <Grid justifyContent="center" sx={{ direction: 'row' }}>
            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[400] }}>
              {LanguageConverter('dashboard.widget.openingsVsPlaced.percentStaffed')}
            </Typography>
            <Typography
              sx={{
                fontSize: '40px',
                fontFamily: PRIMARY_FONT[300],
                color: '#491599',
                justifySelf: 'center',
              }}
            >
              {`${percentStaffed.toLocaleString('en-US')}%`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container>
            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
              {LanguageConverter('dashboard.widget.openingsVsPlaced.employeeCancellations')}
            </Typography>

            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[700] }}>
              {`${'\xa0'}${totalWorkerCancellations.toLocaleString('en-US')}`}
            </Typography>
          </Grid>
          <Grid container>
            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[300] }}>
              {LanguageConverter('dashboard.widget.openingsVsPlaced.cancellationToPlacementRatio')}
            </Typography>
            <Typography sx={{ fontSize: '16px', fontFamily: PRIMARY_FONT[700] }}>{`${'\xa0'}${(
              cancellationToPlacementRatio * 100
            ).toFixed(0)}%`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    )}
  </Box>
);

OpeningsAndPlacementsWidget.propTypes = {
  isLoading: PropTypes.bool,
  totalOpenings: PropTypes.number.isRequired,
  totalActivePlacements: PropTypes.number.isRequired,
  percentStaffed: PropTypes.number.isRequired,
  totalWorkerCancellations: PropTypes.number.isRequired,
  cancellationToPlacementRatio: PropTypes.number.isRequired,
};

export default OpeningsAndPlacementsWidget;
