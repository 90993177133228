import { isRequiredFieldEmpty } from '../../../../../../main/components/Form/validations';
import FormSelectDropdownField from '../../../../../components/Form/FormSelectDropdownField';
import FormTextField from '../../../../../components/Form/FormTextField';
import WaeMap from '../../../../../components/Map/WaeMap';
import theme from '../../../../../theme';

const detailViewTypographySemiBoldText = theme?.components?.detailView?.typography?.semiBoldText;

const states = [
  { id: 'AL', name: 'AL', value: 'AL' },
  { id: 'AK', name: 'AK', value: 'AK' },
  { id: 'AZ', name: 'AZ', value: 'AZ' },
  { id: 'AR', name: 'AR', value: 'AR' },
  { id: 'CA', name: 'CA', value: 'CA' },
  { id: 'CO', name: 'CO', value: 'CO' },
  { id: 'CT', name: 'CT', value: 'CT' },
  { id: 'DE', name: 'DE', value: 'DE' },
  { id: 'FL', name: 'FL', value: 'FL' },
  { id: 'GA', name: 'GA', value: 'GA' },
  { id: 'HI', name: 'HI', value: 'HI' },
  { id: 'ID', name: 'ID', value: 'ID' },
  { id: 'IL', name: 'IL', value: 'IL' },
  { id: 'IN', name: 'IN', value: 'IN' },
  { id: 'IA', name: 'IA', value: 'IA' },
  { id: 'KS', name: 'KS', value: 'KS' },
  { id: 'KY', name: 'KY', value: 'KY' },
  { id: 'LA', name: 'LA', value: 'LA' },
  { id: 'ME', name: 'ME', value: 'ME' },
  { id: 'MD', name: 'MD', value: 'MD' },
  { id: 'MA', name: 'MA', value: 'MA' },
  { id: 'MI', name: 'MI', value: 'MI' },
  { id: 'MN', name: 'MN', value: 'MN' },
  { id: 'MS', name: 'MS', value: 'MS' },
  { id: 'MO', name: 'MO', value: 'MO' },
  { id: 'MT', name: 'MT', value: 'MT' },
  { id: 'NE', name: 'NE', value: 'NE' },
  { id: 'NV', name: 'NV', value: 'NV' },
  { id: 'NH', name: 'NH', value: 'NH' },
  { id: 'NJ', name: 'NJ', value: 'NJ' },
  { id: 'NM', name: 'NM', value: 'NM' },
  { id: 'NY', name: 'NY', value: 'NY' },
  { id: 'NC', name: 'NC', value: 'NC' },
  { id: 'ND', name: 'ND', value: 'ND' },
  { id: 'OH', name: 'OH', value: 'OH' },
  { id: 'OK', name: 'OK', value: 'OK' },
  { id: 'OR', name: 'OR', value: 'OR' },
  { id: 'PA', name: 'PA', value: 'PA' },
  { id: 'RI', name: 'RI', value: 'RI' },
  { id: 'SC', name: 'SC', value: 'SC' },
  { id: 'SD', name: 'SD', value: 'SD' },
  { id: 'TN', name: 'TN', value: 'TN' },
  { id: 'TX', name: 'TX', value: 'TX' },
  { id: 'UT', name: 'UT', value: 'UT' },
  { id: 'VT', name: 'VT', value: 'VT' },
  { id: 'VA', name: 'VA', value: 'VA' },
  { id: 'WA', name: 'WA', value: 'WA' },
  { id: 'WV', name: 'WV', value: 'WV' },
  { id: 'WI', name: 'WI', value: 'WI' },
  { id: 'WY', name: 'WY', value: 'WY' },
];

const FormFieldData = {
  desc: {
    ComponentType: FormTextField,
    key: 'descField',
    name: 'desc',
    multiline: false,
    labelKey: 'location.create.desc',
    fullWidth: true,
    required: true,
    validate: isRequiredFieldEmpty,
  },
  address1: {
    ComponentType: FormTextField,
    key: 'address1Field',
    name: 'address1',
    multiline: false,
    labelKey: 'location.create.address1',
    fullWidth: true,
    required: true,
    validate: isRequiredFieldEmpty,
  },
  address2: {
    ComponentType: FormTextField,
    key: 'address2Field',
    name: 'address2',
    multiline: false,
    labelKey: 'location.create.address2',
    fullWidth: true,
  },
  city: {
    ComponentType: FormTextField,
    key: 'cityField',
    name: 'city',
    multiline: false,
    labelKey: 'location.create.city',
    required: true,
    validate: isRequiredFieldEmpty,
  },
  zip: {
    ComponentType: FormTextField,
    key: 'zipField',
    name: 'zipcode',
    multiline: false,
    labelKey: 'location.create.zip',
    required: true,
    validate: isRequiredFieldEmpty,
  },
  state: {
    ComponentType: FormSelectDropdownField,
    sx: { height: '45px' },
    fullWidth: true,
    labelKey: 'location.create.state',
    name: 'state',
    readOnlyFormat: (value) => value?.name,
    options: states,
    wrapperSx: {
      maxWidth: '30%',
      marginLeft: theme.spacing(2),
    },
    validate: isRequiredFieldEmpty,
  },

  map: (onClick, center) => ({
    ComponentType: WaeMap,
    markers: [{ position: center }],
    key: 'map',
    name: 'map',
    sx: {
      fontSize: detailViewTypographySemiBoldText?.fontSize,
      color: detailViewTypographySemiBoldText?.fontColor,
      fontFamily: detailViewTypographySemiBoldText?.fontFamily,
      width: '100%',

      borderRadius: '15px',
      height: '400px',
    },
    onClick,
    center,
    zoomLevel: 17,
  }),

  lat: {
    ComponentType: FormTextField,
    key: 'latField',
    name: 'lat',
    multiline: false,
    labelKey: 'location.create.lat',
    required: true,
    validate: isRequiredFieldEmpty,
    readOnly: true,
  },

  lng: {
    ComponentType: FormTextField,
    key: 'lngField',
    name: 'lng',
    multiline: false,
    labelKey: 'location.create.lng',
    required: true,
    validate: isRequiredFieldEmpty,
    readOnly: true,
  },

  companyMap: (center, locations) => {
    const markers = locations.map((l) => ({
      position: {
        lat: l.target.coordinates[1],
        lng: l.target.coordinates[0],
      },
    }));

    return {
      ComponentType: WaeMap,
      key: 'companyMap',
      name: 'companyMap',
      sx: {
        fontSize: detailViewTypographySemiBoldText?.fontSize,
        color: detailViewTypographySemiBoldText?.fontColor,
        fontFamily: detailViewTypographySemiBoldText?.fontFamily,
        width: '100%',

        borderRadius: '15px',
        height: '400px',
      },
      center,
      zoomLevel: 15,
      markers,
    };
  },
};
export default FormFieldData;
