import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import theme from '../../../../../theme';

const NoLocationsCard = () => {
  const cardTheme = theme?.candidate?.components?.groupDetails?.availableShiftCard;

  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: '100%',
        padding: theme.spacing(4),
        backgroundColor: cardTheme?.backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <CardContent>
        <Typography
          variant="h4"
          sx={{
            color: cardTheme?.primaryText?.color,
            fontFamily: cardTheme?.primaryText?.font,
          }}
          align="center"
        >
          Oh,no!
        </Typography>
        <Typography
          variant="body2"
          align="center"
          sx={{
            color: cardTheme?.primaryText?.color,
            fontFamily: cardTheme?.primaryText?.font,
          }}
        >
          There are no locations!
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NoLocationsCard;
