import * as React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import WaeButton, { BUTTON_VARIANT } from '../../../components/Button';
import theme from '../../../theme';
import TYPE_KEYS from '../consts';
import { deleteCert, deleteSkill } from '../reducer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.skillsAndCerts.palette.modalBgColor,
  border: `2px solid ${theme.skillsAndCerts.palette.modalBorder}`,
  borderRadius: '16px',
  boxShadow: 24,
  p: 4,
};

const DeleteSkillOrCertsModal = ({ setTypeOpen, type, skillOrCertId }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    setTypeOpen(null);
  };

  const handleDeleteSkillOrCert = () => {
    if (type === TYPE_KEYS.SKILL) {
      dispatch(
        deleteSkill({
          id: skillOrCertId,
        })
      );
      return;
    }
    if (type === TYPE_KEYS.CERT) {
      dispatch(
        deleteCert({
          fields: {
            id: skillOrCertId,
          },
        })
      );
    }
  };

  return (
    <div>
      <Modal
        open
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{
              fontSize: theme?.skillsAndCerts?.typography?.modalHeader?.fontSize,
              fontFamily: theme?.skillsAndCerts?.typography?.modalHeader?.fontFamily,
              color: theme?.skillsAndCerts?.palette?.modalHeader,
            }}
          >
            {type === TYPE_KEYS.SKILL
              ? LanguageConverter('delete.skill.header')
              : LanguageConverter('delete.cert.header')}
          </Typography>
          <Typography
            sx={{
              fontFamily: theme?.skillsAndCerts?.typography?.modalSubText?.fontFamily,
              fontSize: theme?.skillsAndCerts?.typography?.modalSubText?.fontSize,
              color: theme?.skillsAndCerts?.palette?.modalSubText,
              margin: theme.spacing(2, 0, 5, 0),
            }}
          >
            {type === TYPE_KEYS.SKILL
              ? LanguageConverter('delete.skill.body')
              : LanguageConverter('delete.cert.body')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <WaeButton
              text={LanguageConverter('buttonText.goBack')}
              variant={BUTTON_VARIANT.OUTLINED}
              onClick={handleClose}
              sx={{ marginRight: theme.spacing(1) }}
            />

            <WaeButton
              text={LanguageConverter('button.delete')}
              variant={BUTTON_VARIANT.DELETE}
              onClick={handleDeleteSkillOrCert}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

DeleteSkillOrCertsModal.propTypes = {
  setTypeOpen: PropTypes.func,
  skillOrCertId: PropTypes.string,
  type: PropTypes.string,
};

export default DeleteSkillOrCertsModal;
