export const getAverageCoordinates = ({ locations }) => {
  const targets = locations.map((l) => l.target);
  if (targets.length === 0) return { lat: 0, lng: 0 };

  // Calculate the sum of latitudes and longitudes
  const sum = targets.reduce(
    (acc, target) => {
      acc.lat += target.coordinates[1];
      acc.lng += target.coordinates[0];
      return acc;
    },
    { lat: 0, lng: 0 }
  );

  // Calculate the average latitude and longitude
  const avgLatitude = sum.lat / targets.length;
  const avgLongitude = sum.lng / targets.length;

  return { lat: avgLatitude, lng: avgLongitude };
};

export default getAverageCoordinates;
