import React from 'react';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import intersectionBy from 'lodash/fp/intersectionBy';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import SelectMoreIcon from '../../assets/icons/SelectMoreIcon.svg';
import theme from '../../theme';

import { errorHandler } from './formUtils';

const FormSelectDropdownField = ({
  fieldErrorData,
  fullWidth,
  input,
  isLoading,
  label,
  labelKey,
  mode,
  meta,
  multiple,
  options,
  placeholder,
  required,
  sx,
  wrapperSx,
  variant,
}) => {
  const error = errorHandler(input, meta, fieldErrorData);

  let selectFieldBackground;
  let selectFieldFontColor;

  const MODE_KEYS = {
    DARK: 'dark',
    EXTRADARK: 'extraDark',
    LIGHT: 'light', // Same as default
  };

  switch (mode) {
    case MODE_KEYS.DARK:
      selectFieldBackground = get(
        ['components', 'selectDropdown', 'palette', 'dark', 'backgroundColor'],
        theme
      );
      selectFieldFontColor = get(
        ['components', 'selectDropdown', 'palette', 'dark', 'fontColor'],
        theme
      );
      break;
    case MODE_KEYS.EXTRADARK:
      selectFieldBackground = get(
        ['components', 'selectDropdown', 'palette', 'light', 'backgroundColor'],
        theme
      );
      selectFieldFontColor = get(
        ['components', 'selectDropdown', 'palette', 'light', 'fontColor'],
        theme
      );
      break;
    default: // By default, use light
      selectFieldBackground = get(
        ['components', 'selectDropdown', 'palette', 'light', 'backgroundColor'],
        theme
      );
      selectFieldFontColor = get(
        ['components', 'selectDropdown', 'palette', 'light', 'fontColor'],
        theme
      );
      break;
  }

  const optionSx = {
    fontColor: selectFieldFontColor,
    fontSize: get(['components', 'selectDropdown', 'typography', 'fontSize'], theme),
    fontFamily: get(['components', 'selectDropdown', 'typography', 'fontFamily'], theme),
  };
  const selectSx = {
    backgroundColor: selectFieldBackground,
    borderRadius: get(['components', 'selectDropdown', 'borderRadius'], theme),
    fontSize: get(['components', 'selectDropdown', 'typography', 'fontSize'], theme),
    fontFamily: get(['components', 'selectDropdown', 'typography', 'fontFamily'], theme),
    '&& .MuiInputBase-input': {
      color: selectFieldFontColor,
    },
    '& .MuiSvgIcon-root': {
      padding: theme.spacing(0, 1, 0.5, 0),
    },
    marginTop: theme.spacing(1.5),

    ...sx,
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      required={required}
      variant={variant}
      error={!!error}
      sx={wrapperSx}
    >
      {(labelKey || label) && (
        <InputLabel>{labelKey ? LanguageConverter(labelKey) : label}</InputLabel>
      )}

      <Select
        label={label}
        IconComponent={(props) => (
          <Box
            component="img"
            sx={{
              height: 22,
              width: 22,
            }}
            alt="test"
            src={SelectMoreIcon}
            {...props}
          />
        )}
        inputProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                backgroundColor: selectFieldBackground,
                color: selectFieldFontColor,
                borderRadius: 4,
              },
            },
          },
        }}
        multiple={multiple}
        name={input.name}
        sx={selectSx}
        value={
          multiple
            ? intersectionBy('id', input.value || [], options)
            : find((val) => val.id === (input.value || '').id, options) || ''
        }
        onChange={input.onChange}
        placeholder={placeholder}
      >
        {isLoading ? (
          <MenuItem value="" sx={optionSx}>
            <em>Loading...</em>
          </MenuItem>
        ) : (
          options.map((option) => {
            // Every option should have a unique id
            // Options data format should be [{id:123.., name: "string", disabled: bool}, ...]
            // disabled prop is optional

            const { name: optionName, disabled, id } = option;

            return (
              <MenuItem key={id} value={option} disabled={disabled} sx={optionSx}>
                {optionName}
              </MenuItem>
            );
          })
        )}
      </Select>
      <FormHelperText>{error && LanguageConverter(error)}</FormHelperText>
    </FormControl>
  );
};

FormSelectDropdownField.propTypes = {
  fieldErrorData: PropTypes.string,
  fullWidth: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      // When nothing is selected the value is an empty string (per request of mui)
      PropTypes.string,
      // Non-multiple selection has to be an object
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      // Multiple selection is an array
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
    ]),
  }),
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  meta: PropTypes.shape(),
  mode: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),

  variant: PropTypes.string,
};

export default FormSelectDropdownField;
