import theme from '../../../../main/theme';

export const RootGridStyle = (largerThanMediumScreen) => ({
  alignItems: 'center',
  justifyContent: largerThanMediumScreen ? 'left' : 'center',
  margin: largerThanMediumScreen ? theme.spacing(6.75, 0, 0, 23) : theme.spacing(6.75, 0, 0, 0.5),
});

export const formGridStyle = (largerThanMediumScreen) => ({
  justifyContent: 'center',
  width: largerThanMediumScreen ? '516px' : '300px',
});

export const headerStyle = {
  ...theme?.views?.createCorporation.typography?.title,
  color: theme?.views?.createCorporation.palette?.title.fontColor,
  margin: theme.spacing(0, 0, 6, 0),
};
export const corporationNameTitleStyle = {
  ...theme?.views?.createCorporation.typography?.nameTitle,
  color: theme?.views?.createCorporation.palette?.nameTitle.fontColor,
  margin: theme.spacing(0, 0, 2.5, 0),
};
