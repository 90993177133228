import React from 'react';
import { get, getOr } from 'lodash/fp';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import theme from '../../../../theme';
import { formatCurrency, minutesFromMidnightToTimeOnlyHrAndMins } from '../../../../utils';

const RecruiterShiftDetail = ({ initialValues }) => {
  const componentsDetailViewSectionHeaders = [
    'components',
    'detailView',
    'typography',
    'sectionHeader',
  ];

  const componentsDetailViewFieldHeaders = [
    'components',
    'detailView',
    'typography',
    'fieldHeaders',
  ];

  const componentsDetailViewPtShiftName = ['components', 'detailView', 'typography', 'ptShiftName'];
  const componentsDetailViewSemiBoldText = [
    'components',
    'detailView',
    'typography',
    'semiBoldText',
  ];
  const componentsDetailViewRequireCertsText = [
    'components',
    'detailView',
    'typography',
    'requireCertText',
  ];
  const viewHeaderStyling = {
    fontSize: get([...componentsDetailViewSectionHeaders, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewSectionHeaders, 'fontFamily'], theme),
    color: get([...componentsDetailViewSectionHeaders, 'fontColor'], theme),
    margin: theme.spacing(4, 0, 0, 0),
  };

  const fieldHeaderStyling = {
    fontSize: get([...componentsDetailViewFieldHeaders, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewFieldHeaders, 'fontFamily'], theme),
    color: get([...componentsDetailViewFieldHeaders, 'fontColor'], theme),
    margin: theme.spacing(0, 0, 1.5, 0),
  };

  const readOnlyFieldBoldValueStyling = {
    fontSize: get([...componentsDetailViewSemiBoldText, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewSemiBoldText, 'fontFamily'], theme),
    color: get([...componentsDetailViewSemiBoldText, 'fontColor'], theme),
  };

  const readOnlyFieldValueStyling = {
    fontSize: get([...componentsDetailViewPtShiftName, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewPtShiftName, 'fontFamily'], theme),
    color: get([...componentsDetailViewPtShiftName, 'fontColor'], theme),
  };

  const requireCertStyling = {
    fontSize: get([...componentsDetailViewRequireCertsText, 'fontSize'], theme),
    fontFamily: get([...componentsDetailViewRequireCertsText, 'fontFamily'], theme),
    color: get([...componentsDetailViewRequireCertsText, 'fontColor'], theme),
    margin: theme.spacing(0, 0, 2, 0),
  };

  const shiftReadOnlyData = getOr([], 'shifts', initialValues).map((shift) => ({
    name: get('name', shift),
    value: `${minutesFromMidnightToTimeOnlyHrAndMins(
      get('start', shift)
    )} - ${minutesFromMidnightToTimeOnlyHrAndMins(get('end', shift))} `,
  }));

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          overflowY: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          height: '80vh',
        }}
      >
        <Grid container direction="column" sx={{ width: '100%' }}>
          <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(1, 0, 1.5, 0) }}>
            Shifts
          </Typography>
          <Grid container item>
            {shiftReadOnlyData.map((shift) => (
              <Grid container item justifyContent="space-between">
                <Typography sx={readOnlyFieldValueStyling}>{get('name', shift)}</Typography>
                <Typography sx={readOnlyFieldBoldValueStyling}>{get('value', shift)}</Typography>
              </Grid>
            ))}
          </Grid>
          <Typography sx={viewHeaderStyling}>Bill Rate</Typography>
          <Grid container item sx={{ margin: theme.spacing(1.5, 0, 0, 0) }}>
            <Grid item xs={6}>
              <Typography sx={fieldHeaderStyling}>Bill Rate</Typography>
              <Typography sx={readOnlyFieldBoldValueStyling}>
                {formatCurrency(get('billRateReg', initialValues))}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={fieldHeaderStyling}>Double Time Bill Rate</Typography>
              <Typography sx={readOnlyFieldBoldValueStyling}>
                {formatCurrency(get('billRateOT', initialValues))}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ margin: theme.spacing(3, 0, 0, 0) }}>
              <Typography sx={fieldHeaderStyling}>Over Time Bill Rate</Typography>
              <Typography sx={readOnlyFieldBoldValueStyling}>
                {formatCurrency(get('billRateDT', initialValues))}
              </Typography>
            </Grid>
          </Grid>
          <Typography sx={viewHeaderStyling}>Pay Rate</Typography>
          <Grid container item sx={{ margin: theme.spacing(1.5, 0, 0, 0) }}>
            <Grid item xs={6}>
              <Typography sx={fieldHeaderStyling}>Pay Rate</Typography>
              <Typography sx={readOnlyFieldBoldValueStyling}>
                {formatCurrency(get('payRateReg', initialValues))}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={fieldHeaderStyling}>Double Time Pay Rate</Typography>
              <Typography sx={readOnlyFieldBoldValueStyling}>
                {formatCurrency(get('billRateDT', initialValues))}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ margin: theme.spacing(3, 0, 0, 0) }}>
              <Typography sx={fieldHeaderStyling}>Over Time Pay Rate</Typography>
              <Typography sx={readOnlyFieldBoldValueStyling}>
                {formatCurrency(get('payRateOT', initialValues))}
              </Typography>
            </Grid>
          </Grid>
          <Typography sx={viewHeaderStyling}>Overstaffing Percentage</Typography>
          <Grid container item sx={{ margin: theme.spacing(1.5, 0, 0, 0) }}>
            <Grid item xs={6}>
              <Typography sx={fieldHeaderStyling}>Overstaffing Percentage</Typography>
              <Typography sx={readOnlyFieldBoldValueStyling}>
                {initialValues?.overstaffingPercent ?? 0}
              </Typography>
            </Grid>
          </Grid>
          <Typography sx={viewHeaderStyling}>Workers Comp</Typography>
          <Grid container item sx={{ margin: theme.spacing(1.5, 0, 0, 0) }}>
            <Grid item xs={6}>
              <Typography sx={fieldHeaderStyling}>Workers Comp Code</Typography>
              <Typography sx={readOnlyFieldBoldValueStyling}>
                {get('workersCompCode', initialValues)}
              </Typography>
            </Grid>
          </Grid>
          <Typography sx={{ ...viewHeaderStyling, margin: theme.spacing(4, 0, 1.5, 0) }}>
            Required Certifications
          </Typography>
          <Grid item xs={12}>
            <Typography sx={requireCertStyling}>
              {getOr([], 'certifications', initialValues)
                .map((certs) => certs.name)
                .toString()}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

RecruiterShiftDetail.propTypes = {
  initialValues: PropTypes.shape({
    overstaffingPercent: PropTypes.number,
  }),
};

export default RecruiterShiftDetail;
