import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid } from '@mui/material';

import WAEMap from '../Map/WaeMap';

const FormMapWrapper = (props) => {
  const { sx, input } = props;
  const center = {
    lat: input?.value?.latitude || 0,
    lng: input?.value?.longitude || 0,
  };

  const markers = [
    {
      position: center,
    },
  ];

  return (
    <Grid item sx={sx}>
      <Box sx={sx}>
        <WAEMap center={center} markers={markers} zoomLevel={6} />
      </Box>
    </Grid>
  );
};

export default FormMapWrapper;
FormMapWrapper.propTypes = {
  sx: PropTypes.shape({}),
  input: PropTypes.shape({
    value: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  }),
};
