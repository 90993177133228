import React, { memo } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import theme from '../../../../main/theme';
import { BUTTON_VARIANT } from '../../../components/Button';
import Form from '../../../components/Form';
import {
  selectErrors,
  selectIsProcessingPostCorp,
} from '../../../store/selectors/createCorporationSelectors';
import launchDarklyToggles from '../../../utils/launchDarklyToggles';

import { corporationNameTitleStyle, formGridStyle, headerStyle, RootGridStyle } from './styles';

const CreateCorporationForm = memo(({ formData, onSubmit, flags }) => {
  const navigate = useNavigate();
  const isProcessingPostCorp = useSelector(selectIsProcessingPostCorp);
  const errorData = useSelector(selectErrors);
  const intl = useIntl();
  const largerThanMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isBhDecouple = launchDarklyToggles(flags, 'isWaeBhDecoupled');
  return (
    <Grid container sx={RootGridStyle(largerThanMediumScreen)}>
      <Grid item sx={formGridStyle(largerThanMediumScreen)}>
        <Grid sx={headerStyle}>{intl.formatMessage({ id: 'corporation.create.title' })}</Grid>
        {isBhDecouple && (
          <Grid sx={corporationNameTitleStyle}>
            {intl.formatMessage({ id: 'corporation.create.nameTitle' })}
          </Grid>
        )}
        <Form
          buttonData={[
            {
              onClick: () => navigate('/corporations/corporationList'),
              text: `${intl.formatMessage({ id: 'corporation.createButton.goBack' })}`,
              variant: BUTTON_VARIANT.OUTLINED,
              sx: {
                margin: theme.spacing(0, 6.25, 0, 0),
              },
            },
          ]}
          confirmationModal={{
            buttonText: `${intl.formatMessage({ id: 'corporation.createButton.add' })}`,
            submitting: isProcessingPostCorp,
          }}
          formFieldData={formData}
          onSubmit={onSubmit}
          errorData={errorData}
        />
      </Grid>
    </Grid>
  );
});

CreateCorporationForm.propTypes = {
  flags: PropTypes.shape({}),
  formData: PropTypes.arrayOf(PropTypes.shape({})),
  onSubmit: PropTypes.func,
};

export default withLDConsumer()(CreateCorporationForm);
